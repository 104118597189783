import React, { FC } from "react"
import { graphql } from "gatsby"
import marked from "marked"
import SEO from "../components/SEO"
import Layout from "../components/Layout"
import { PageProps } from "../types"

interface DocumentPageTemplateProps {
  html: string
}

const DocumentPageTemplate: FC<DocumentPageTemplateProps> = ({
  html
}) => (
  <main
    id="document-page"
    className="page px-4"
  >
    <div
      className="w-full max-w-4xl mx-auto markdown my-16"
      dangerouslySetInnerHTML={{
        __html: marked(html)
      }}
    />
  </main>
)

interface DocumentPageProps {
  data: {
    markdownRemark: {
      frontmatter: DocumentPageTemplateProps & PageProps,
      html: string
    }
  }
}

const DocumentPage: React.FC<DocumentPageProps> = ({ data }) => {
  const { frontmatter, html } = data.markdownRemark
  return (
    <Layout>
      <SEO
        title={frontmatter.meta.title}
        description={frontmatter.meta.description}
        image={frontmatter.meta.image}
      />

      <DocumentPageTemplate
        html={html}
      />
    </Layout>
  )
}

export default DocumentPage

export const DocumentPageQuery = graphql`
  query DocumentPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        meta {
          title
          description
          image {
            childImageSharp {
              fluid(quality: 100 maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      html
    }
  }
`
